<template>
  <page title="我的订单" >
    <div class=" top-tabs hairline-bottom relative" slot="fixed-top">
      <cube-scroll-nav-bar :current="current" :labels="labels" @change="changeHandler" class="bg-gray-50" />
    </div>
    <div class="tab-content" v-for="(itm, index) in labels" :key="index">
      <list v-if="itm == current">
        <media-item 
          :image="item.image" 
          :title="itm+item.title"  
          img-ratio="16/15"
          img-width="90"
          note="数量：202台"
          class="flex-1"
          v-for="item in TestData.examines" 
          :key="item.id" 
          :url="`/order-detail/${item.id}`"
        >
          <div class="text-gray-light mt-1 text-xs">
            订单编号：20202225522222
          </div>
          <div class="flex items-center justify-between mt-2" >
            <div class="text-red-600 font-medium"><span class="text-xs">¥</span>89.9</div>
            <tag color="green">已完成</tag>
          </div>
        </media-item>
      </list>

    </div>
    <div class="h-12 pl-4 bg-gray-50 relative hairline-top  flex items-center" slot="footer">
      <div class="flex-1 flex items-center ">
        合计：<span class="text-lg font-medium text-red-500">3920.00</span>
      </div>
      <btn color="red-500"  size="xl" radius="none"  @click="goPay" text-size="base">去支付</btn>
    </div>
  </page>
</template>

<script>
import {mapGetters} from 'vuex'
export default {
  data(){
    return{
      current: '快车',
      labels: [
        '快车',
        '小巴',
        '专车',
        '顺风车',
        '代驾',
        '公交',
        '自驾租车',
        '豪华车',
        '二手车',
        '出租车'
      ],
    }
  },
  computed: {
    ...mapGetters([
      'TestData'
    ])
  },
  methods:{
    changeHandler(cur) {
      this.current = cur;
      console.log(cur);
    },
    goPay(){
      this.$router.push('/paid')
    }
  },
  created(){}
}
</script>

<style lang='stylus'>

</style>